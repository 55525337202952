/*
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * (c) 2016
 */

 /* general */
* {box-sizing: border-box;}
html {-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;}
a {color: #dd7b23; outline: none; text-decoration: none; transition: color 0.3s;}
a:hover {color: inherit; text-decoration: none;}
input {border-radius: 0;}
em {color: inherit;}

/* uikit specific */
p {margin: 25px 0;}
[class*=uk-icon-] {vertical-align: initial;}
.uk-table th {background: #f1692e; color: #fff;}
h1, h2, h3, h4, h5, h6 {font-family: 'Fira Sans', arial, sans-serif; font-weight: 500;}

/* all pages */
body {background: #fff; color: #000; font-family: 'Fira Sans', arial, sans-serif; font-size: 16px; line-height: 22px;}
header {height: 180px; position: relative; z-index: 10;}
.wrapper {margin: auto; padding: 0 26px; position: relative; width: 1200px;}
.logo {left: 20px; position: absolute; transition: all 0.2s ease-in-out; top: 26px;}
.logo svg {width: 340px;}
.stock-symbol {color: #000; font-size: 16px; font-weight: bold; line-height: 40px; overflow: hidden; position: absolute; right: 20px; top: 0;}
.stock-symbol .edge {background: #fff; float: left; height: 70px; margin: -38px -28px 0 0; position: relative; transform: rotate(45deg); width: 70px;}
.stock-symbol .edge:after {border-top: 16px solid #f58936; content: ''; left: 12px; position: absolute; transform: rotate(-90deg); top: 40px; width: 100px;}
.stock-symbol .panel {background: -moz-linear-gradient(left, rgba(222,222,222,1) 0%, rgba(222,222,222,1) 85%, rgba(222,222,222,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, rgba(222,222,222,1) 0%,rgba(222,222,222,1) 85%,rgba(222,222,222,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(222,222,222,1) 0%,rgba(222,222,222,1) 85%,rgba(222,222,222,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
float: right; padding: 0 20px 0 35px;}
.stock-symbol .last_price {font-weight: normal; margin: 0 0 0 10px;}

.main-nav {margin: 0; padding: 0; position: absolute; right: 20px; top: 116px;}
.main-nav li {display: inline-block; position: relative;}
.main-nav li a {display: block; font-family: 'Fira Sans', sans-serif;}
.main-nav > li:last-child {margin: 0;}
.main-nav > li > a {color: #000; font-size: 16px; line-height: 24px; padding: 0 10px; text-align: center; transition: all 0.3s;}
.main-nav > li:last-child > a {padding: 0 0 0 6px;}
.main-nav > li:hover > a {}
.main-nav > li.uk-active > a {color: #f1632c;}
.main-nav li ul {background: #f1692e; display: none; left: 0; padding: 10px; position: absolute; top: 100%;}
.main-nav li ul li {list-style: none; padding: 6px 0;}
.main-nav li ul li a {color: #fff; font-size: 16px; height: auto; line-height: 18px; padding: 0 12px; text-align: left; width: 160px;}
.main-nav li ul li a:hover {color: #000;}
.main-nav li ul li ul {left: 100%; top: 0;}

#slider {position: relative; width: 100%;}
#slider .slide {height: 450px; width: 100%;}
#slider * {transition: all 0.3s;}
#banner {height: 270px; position: relative; width: 100%;}
#banner .wrapper {height: 100%;}
#banner h2 {color: #fff; font-size: 52px; line-height: 56px; padding: 0 0 0 26px; position: absolute; transform: translateY(-50%); top: 50%;}
#banner h2:before {border-left: 6px solid #f1692e; content: ''; height: 52px; left: 10px; position: absolute; transform: rotate(18deg);}

.main-home {margin: 40px 0;}
.main-home h2 {color: #f1692e; font-family: 'Fira Sans', sans-serif; font-size: 26px; font-weight: 500; line-height: 30px; margin: 0 0 15px;}
.main-home .col-1 {float: left; padding: 0 0 20px; position: relative; width: 50%;}
.main-home .col-1 h2 {margin: 25px 0 15px;}
.main-home .col-2 {float: left; padding: 0 0 0 60px; width: 50%;}
.main-home .l-news {margin: 0 0 30px;}
.main-home .l-news .uk-panel-box {padding: 26px 15px 15px;}
.main-home .l-news .entry {border-bottom: 1px solid #919396; display: block; font-size: 16px; line-height: 22px; margin: 0 0 20px; padding: 0 0 20px;}
.main-home .l-news .entry:last-child {border: none; margin: 0;}
.main-home .l-news .date {background: #d4d5d6; color: #000; float: left; font-weight: 500; padding: 4px 0; text-align: center; transition: all 0.3s; width: 93px;}
.main-home .l-news a:hover .date {background: #f1632c;}
.main-home .l-news .date span {display: block;}
.main-home .l-news .detail {color: #000; margin: 0 0 0 105px;}
.main-sub {margin: 40px 0;}
.main-col {float: right; width: 73%;}
.main-col h2 {color: #f1692e; font-size: 26px; font-weight: 500; line-height: 40px; margin: 0 0 20px; padding: 0 0 6px;}
.side-col {float: left; width: 22%;}
.side-nav {box-shadow: 0 1px #999; list-style-type: none; margin: 0; padding: 0;}
.side-nav li {box-shadow: 0 1px #999 inset; padding: 0 12px; position: relative;}
.side-nav li a {color: #333; display: block; font-size: 16px; line-height: 18px; padding: 14px 0;}
.side-nav li.uk-active a {font-weight: bold;}
.side-nav li.uk-active a:before {border-left: 3px solid #f1692e; content: ''; height: 16px; left: 2px; position: absolute; transform: rotate(18deg);}

.features {background: #f6f7f7; padding: 30px 0;}
.features .grid {margin: 0 0 0 -14px;}
.features .col {float: left; padding: 0 0 0 14px; width: 33.3%;}
.features .col a {background: -webkit-linear-gradient(left, rgba(238,146,20,1) 0%,rgba(238,146,20,1) 40%,rgba(238,139,24,1) 60%,rgba(241,104,44,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(238,146,20,1) 0%,rgba(238,146,20,1) 40%,rgba(238,139,24,1) 60%,rgba(241,104,44,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
display: block; height: 90px; overflow: hidden; position: relative;}
.features .left {background: #494545; border-radius: 70px; float: left; height: 150px; margin: -28px 0 0 -22px; width: 150px;}
.features .left .arrow {color: #ee9214; display: block; font-size: 40px; left: 45px; line-height: 40px; position: absolute; transition: 0.3s all; top: 28px;}
.features .col:hover .arrow {color: #f1662d; left: 50px;}
.features .title {color: #fff; float: right; font-size: 20px; line-height: 22px; padding: 0 70px 0 20px; position: absolute; top: 50%; transform: translateY(-50%);}

footer {color: #000; font-size: 16px; line-height: 22px; margin: 0; padding: 20px 0;}
footer .col-1 {float: left;}
footer .col-1 ul {margin: 0; padding: 0;}
footer .col-1 ul li {display: inline-block; list-style: none;}
footer .col-1 ul li a {color: #000; display: inline-block; padding: 0 10px;}
footer .col-1 ul li.uk-active a {color: #f1632c;}
footer .col-2 {float: right; padding: 0 10px 0 0;}

/* mobile-nav */
.mobile-nav-btn {display: block; height: 50px; position: absolute; right: 30px; top: 30px; width: 50px;}
.mobile-nav-btn span {background: #333; display: inline-block; height: 3px; left: 11px; position: absolute; text-indent: -9999px; top: 22px; width: 25px;}
.mobile-nav-btn span:before, .mobile-nav-btn span:after {background-color: inherit; content: ''; height: 100%; left: 0; position: absolute; transition: 0.3s all; width: 100%;}
.mobile-nav-btn span:before {bottom: 9px;}
.mobile-nav-btn span:after {top: 9px;}
.mobile-nav-btn.is-clicked span {background: none;}
.mobile-nav-btn.is-clicked span:before {background: #333; bottom: 0; transform: rotate(45deg);}
.mobile-nav-btn.is-clicked span:after {background: #333; top: 0; transform: rotate(-45deg);}
.mobile-nav {background: #eee; border-left: 1px solid #ccc; box-shadow: 0 0 10px rgba(0,0,0,.3); height: calc(100% - 100px); overflow-y: auto; position: fixed; right: 0; top: 100px; visibility: hidden; width: 260px; z-index: 1;
    transition: transform .3s 0s, visibility 0s .3s; transform: translateX(260px);
    -webkit-transform: translateZ(0); -webkit-backface-visibility: hidden; -webkit-transition: -webkit-transform .3s 0s, visibility 0s .3s; -moz-transition: -moz-transform .3s 0s, visibility 0s .3s; -webkit-transform: translateX(260px); -moz-transform: translateX(260px); -ms-transform: translateX(260px); -o-transform: translateX(260px);}
.mobile-nav.mobile-nav-is-open {transform: translateX(0); visibility: visible; transition: transform .3s 0s, visibility 0s 0s;
    -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0);
    -webkit-transition: -webkit-transform .3s 0s, visibility 0s 0s; -moz-transition: -moz-transform .3s 0s, visibility 0s 0s; -webkit-overflow-scrolling: touch;}
.mobile-nav ul {list-style-type: none; margin: 0; padding: 0;}
.mobile-nav ul.nav li {box-shadow: 0 1px #ccc inset;}
.mobile-nav ul.nav li a {display: block; font-size: 16px; line-height: 50px; padding: 0 20px;}
.mobile-nav ul.nav li.has-sub a:after {content: '\f105'; float: right; font-family: 'FontAwesome'; font-size: 20px;}
.mobile-nav ul.nav li.has-sub.sub-open > a {color: #000;}
.mobile-nav ul.nav li.has-sub.sub-open a:after {content: '\f107';}
.mobile-nav ul.nav li.has-sub ul.sub li a:after {content: '';}
.mobile-nav ul.sub {display: none;}
.mobile-nav ul.sub li {padding: 9px 10px;}
.mobile-nav ul.sub li a {font-size: 14px; line-height: 20px;}
.mobile-nav ul.stock {color: #525252; font-size: 14px; line-height: 40px; overflow: hidden; padding: 0 20px;}
.mobile-nav ul.stock li {float: left; margin: 0 12px 0 0;}
.mobile-nav ul.stock li.price {float: right; margin: 0;}
.mobile-nav ul.stock li a {display: inline-block; margin: 0 12px 0 0;}
.mobile-nav ul.copyright {box-shadow: 0 1px #ccc inset; color: #525252; font-size: 14px; line-height: 20px; padding: 14px 20px; text-align: center;}

/* Large desktop */
@media (max-width: 1200px) {
.wrapper {width: 100%;}
.features .title {padding: 0 20px;}
}

/* Desktop and smaller */
@media (max-width: 960px) {
header {background: #fff; box-shadow: 0 0 10px rgba(0,0,0,.5); height: 100px; position: fixed; width: 100%;}
.logo {top: 16px;}
.logo svg {width: 200px;}
.stock-symbol {display: none;}
#slider .slide {height: 340px;}
.main-body {padding: 100px 0 0;}
.main-home {margin: 0;}
.main-home .col {float: none; padding: 0; width: 100%;}
.main-sub {margin: 40px 0 0;}
.features .left {margin: -28px 0 0 -60px;}
.features .left .arrow {left: 30px;}
.features .col:hover .arrow {left: 35px;}
.features .title {font-size: 18px; line-height: 20px;}
.main-col {float: none; margin: 0 0 30px; width: 100%;}
.side-col {float: none; width: 100%;}
footer {font-size: 14px;}
footer .col-1 ul li a {font-size: 14px; padding: 0 5px;}
}

/* Phone landscape and smaller*/
@media (max-width: 767px) {
body {font-size: 14px; line-height: 20px;}
#slider .slide {height: 260px;}
#banner {height: 200px;}
#banner h2 {font-size: 40px; line-height: 46px;}
#banner h2:before {height: 42px;}
.main-home h2, .main-sub h2 {font-size: 20px; line-height: 24px;}
.main-home .l-news .entry {font-size: 14px; line-height: 20px;}
.features .grid {margin: 0;}
.features .col {float: none; margin: 0 0 10px; padding: 0; width: 100%;}
footer .col {float: none; margin: 0 0 10px; text-align: center; width: 100%;}
}

/* Phone portrait and smaller*/
@media (max-width: 480px) {
.wrapper {padding: 0 15px;}
#banner h2 {font-size: 28px; line-height: 36px; padding: 0 0 0 22px;}
#banner h2:before {border-left: 4px solid #f1692e; height: 32px;}
.main-home .l-news .date {width: 78px;}
.main-home .l-news .detail {margin: 0 0 0 90px;}
}
